import * as React from "react"
import Icon from "../Icon/Icon"

import "./ProjectAppLinks.sass"

class ProjectAppLinks extends React.Component<{
  title: string,
  message: string,
  icon: string,
  className?: string,
  appStoreLink?: string,
  googlePlayLink?: string,
}> {

  render() {
    const {title, message, icon, appStoreLink, googlePlayLink, className} = this.props;

    return (
      <div className={`project-link ${className}`}>
        <div className='max-width'>
          <Icon name={icon} raw className='project-link__icon'/>
          <h2 className='project-link__title'>{title}</h2>
          <div className='project-link__description'>{message}</div>
          <div className='project-link__controls'>
            {!!appStoreLink && (
              <a href={appStoreLink} target='_blank' title={`Link to ${title} app in AppStore`}>
                <Icon name='icon-app-store' raw/>
              </a>
            )}
            {!!googlePlayLink && (
              <a href={googlePlayLink} target='_blank' title={`Link to ${title} app in Google Play`}>
                <Icon name='icon-google-play' raw/>
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectAppLinks
