import * as React from 'react';
import cn from 'classnames';

import './BurgerButton.sass';

class BurgerButton extends React.Component<{
  isOpened: boolean,
  onClick: () => void
}> {
  render() {
    const { isOpened, onClick } = this.props;
    return (
      <button
        aria-label='Burger Menu Button'
        className={cn('burger-button', { '-opened': isOpened })}
        onClick={onClick}
      >
        <span/>
        <span/>
      </button>
    );
  }
}

export default BurgerButton;
