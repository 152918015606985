import { string } from "prop-types"
import * as React from 'react';
import cn from 'classnames';
import BlockHeader from '../BlockHeader/BlockHeader';
import Icon from '../Icon/Icon';

import './ProjectBlock.sass';

type ProjectBlockImageType = {
  src: string,
  alt: string
}

class ProjectBlock extends React.Component<{
  className: string,
  heading: string,
  title: string,
  subtitle: string,
  link?: string,
  linkTitle?: string,
  image?: ProjectBlockImageType,
  children?: any,
  centered?: boolean
  centeredContent?: boolean
  overlappedHeading?: boolean
  isImageFirst?: boolean
  titleGap?: boolean
  headerCentered?: boolean
}> {

  render() {

    const { className, heading, title, subtitle, image, children, overlappedHeading, headerCentered, centeredContent, titleGap, centered, isImageFirst, link, linkTitle } = this.props;

    return (
      <div className={cn('project-block', className, {
        'max-width'    : !centered,
        '-column'      : centered,
        '-image-first' : isImageFirst,
      })}>
        <BlockHeader
          title={title}
          link={link}
          linkTitle={linkTitle}
          orientation='vertical'
          centeredContent={centeredContent}
          overlappedHeading={overlappedHeading}
          centered={headerCentered !== undefined ? headerCentered : centered}
          titleGap={titleGap}
          subtitle={subtitle}
        >
          <Icon name={heading} className='heading' raw/>
        </BlockHeader>
        <div className='project-block__content'>
          {!!children && children}
          {!!image && (
            <img src={image.src} alt={image.alt}/>
          )}
        </div>
      </div>
    );
  }
}

export default ProjectBlock;
