import * as React from 'react';
import Icon from '../Icon/Icon';
import SiteLink from '../SiteLink/SiteLink';

import './ProjectIntro.sass';

type ProjectInfoType = {
  label: string,
  value: string,
  isLink?: boolean
  linkLabel?: string
}

class ProjectIntro extends React.Component<{
  title: string,
  description: string,
  heading: string,
  info: ProjectInfoType[],
  link?: string,
  linkTitle?: string,
  children?: any
}> {

  render() {
    const { title, description, info, heading, children, link, linkTitle } = this.props;

    return (
      <div className='project-intro'>
        <div className='max-width'>
          <Icon name={heading} className='heading' raw/>
          <div className='project-intro__left'>
            <h1 className='project-intro__title' dangerouslySetInnerHTML={{ __html : title }}/>
            <div className='project-intro__description'>
              {description}
            </div>
            {!!link && (
              <SiteLink linkTitle={linkTitle} link={link}/>
            )}
            <div className='project-intro__info'>
              {info.map(item => (
                  <div className='project-intro__info-block' key={item.value}>
                    <div className='project-intro__info-label'>{item.label}</div>
                    {item.isLink ? (
                      <a target='_blank' href={item.value} className='project-intro__info-value'>
                        <Icon name='icon-visit-site'/>
                        {item.linkLabel || item.value}
                      </a>
                    ) : (
                      <div className='project-intro__info-value'>{item.value}</div>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
          <div className='project-intro__right'>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectIntro;
