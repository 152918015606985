import * as React from 'react';
import Header from '../../../Header/Header';
import ProjectAppLinks from '../../../UI/ProjectAppLinks/ProjectAppLinks';
import ProjectBlock from '../../../UI/ProjectBlock/ProjectBlock';
import ProjectFooter from '../../../UI/ProjectFooter/ProjectFooter';
import ProjectGoals from '../../../UI/ProjectGoals/ProjectGoals';
import ProjectIntro from '../../../UI/ProjectIntro/ProjectIntro';

import './Motivetica.sass';

const challenges = [
  'Creative yet simple interface',
  'Interactive UI to drive exploration',
  'Custom made text input control for user\'s quotes',
  'Apple Subscription integration',
  'Interaction with dynamic phone wallpapers',
];

const solutions = [
  'Unified style wired to the font (Helvetica);',
  'Dynamic sizing box with custom text sizing algorithms;',
];

class Motivetica extends React.Component {

  colorBlock = React.createRef<HTMLDivElement>();
  observer;
  interval;

  componentDidMount(): void {
    this.observer = new IntersectionObserver(this.setIntervalForColor);
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer.observe(this.colorBlock.current);
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  setIntervalForColor = (entries) => {
    if (!!entries && !entries[0].isIntersecting) return null;

    const observerTarget = document.querySelector('.motivetica__color-observer');
    if (!!observerTarget) {
      observerTarget.classList.remove('motivetica__color-observer');
    }
    this.interval = setInterval(this.changeColor, 5000);
    this.observer = this.observer.disconnect();
  };

  changeColor = () => {
    const section = document.querySelector('.motivetica__color') as HTMLElement;
    const classString = section.getAttribute('class');
    if (classString.includes('-white')) {
      section.classList.remove('-white');
    } else {
      section.classList.add('-white');
    }
  };

  onChangeColorClick = () => {
    clearInterval(this.interval);
    this.changeColor();
    this.interval = setInterval(this.changeColor, 7000);
  };

  render() {

    const info = [
      { label : 'Client', value : 'Spiilka Design Büro' },
      { label : 'Partnership with', value : 'SPIILKA' },
      { label : 'Category', value : 'Mobile app' },
      { label : 'Year', value : '2018' },
    ];

    return (
      <main className='motivetica'>
        <Header/>
        <ProjectIntro title='Motivetica'
                      description='Motivetica (Motivation + Helvetica) is an iOS application that shows you inspirational quotes in Helvetica and allows users to set the favorite ones as phone wallpapers.'
                      heading='heading-case'
                      info={info}>
          <img src='../../../../images/projects/motivetica.png'
               alt='Illustration: robot and shark'/>
        </ProjectIntro>
        <ProjectGoals challenges={challenges} solutions={solutions}/>
        <ProjectBlock className='motivetica__motivate'
                      heading='heading-num1'
                      title='Inspirational Simplicity'
                      subtitle="Application UI is designed and developed with an ultra minimalistic approach that inspires user to explore the content and to create their own."
                      image={{
                        src : '../../../../images/projects/motivetica/motivetica-app-on-black.jpg',
                        alt : 'Illustration: Iphone with Motivetica app on black plastic',
                      }}
                      isImageFirst={true}/>
        <div className='max-width'>
          <div className='motivetica__phones'/>
        </div>
        <ProjectBlock className='motivetica__color'
                      heading='heading-num2'
                      title="It's Black & White"
                      subtitle="Application supports for the night mode goes beyond just switching colors of the ui and background. When you choose a quote, app will show you a custom preview that exactly matches the look on your phone interface."
        >
          <div ref={this.colorBlock} className='motivetica__color-observer'>
            <img src='../../../../images/projects/motivetica/black-app.png' className='-black'
                 alt='Image: iPhone with Motivetica black scheme'/>
            <img src='../../../../images/projects/motivetica/white-app.png' className='-white'
                 alt='Image: iPhone with Motivetica white scheme'/>
          </div>
          <div className='motivetica__color-theme-button' onClick={this.onChangeColorClick}/>
        </ProjectBlock>
        <ProjectBlock className='motivetica__create max-width'
                      heading='heading-num3'
                      title='Quote Designer'
                      subtitle='We made a simple text input to motivate you as you type. Dynamic and fully responsive font size, automatic spaces and empty zones cut out, and intelligent wrapping ensure maximum fulfillment of the available area while always making it look inspiring and simply awesome.'
                      centered={true}
                      image={{
                        src : '../../../../images/projects/motivetica/create-screen.png',
                        alt : 'Image: iPhone with Motivetica on quote construction screen',
                      }}>
        </ProjectBlock>
        <ProjectAppLinks icon='motivetica'
                         title='Motivetica'
                         message='Download Motivetica application and get your everyday motivation.'
                         appStoreLink='https://apps.apple.com/app/motivetica/id1332066030'
                         className='motivetica__links'
        />
        <ProjectFooter title="PublBox"
                       className='motivetica__footer'
                       link='/publbox'
                       imageSrc='../../../../images/projects/publbox-footer.png'
                       imageAlt='Image: Crop image area with toolbars'
        />
      </main>
    );
  }
}

export default Motivetica;
