class HistoryProvider {
  listeners: { [key: string]: ((data?: any) => void)[] } = {}

  addEventListener = (eventName: string, cb: (data?: any) => void) => {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = []
    }

    this.listeners[eventName].push(cb)
  }

  removeEventListener = (eventName: string, cb: (data?: any) => void) => {
    this.listeners[eventName] = this.listeners[eventName].filter(item => item !== cb)
  }

  notify = (eventName: string, data: any) => {
    if (this.listeners[eventName]) {
      this.listeners[eventName].forEach(item => item(data))
    }
  }

}

export default new HistoryProvider()
