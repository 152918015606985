import * as React from 'react';
import Icon from '../Icon/Icon';

import './SiteLink.sass';

type Props = {
  link: string,
  linkTitle: string
}

class SiteLink extends React.Component<Props> {

  render() {
    const { link, linkTitle } = this.props;
    return (
      <div className='site-link'>
        <div className='site-link__label'>
          Visit Website
        </div>
        <a href={link} target='_blank' className='site-link__value'>
          <Icon name='icon-visit-site' raw/>
          <span>{linkTitle || link}</span>
        </a>
      </div>
    );
  }
}

export default SiteLink;
