import cn from 'classnames';
import * as React from 'react';
import SiteLink from '../SiteLink/SiteLink';

import './BlockHeader.sass';

type Props = {
  title: string,
  className?: string,
  subtitle?: string,
  link?: string,
  linkTitle: string,
  centered?: boolean,
  centeredContent?: boolean,
  overlappedHeading?: boolean,
  titleGap?: boolean,
  orientation?: 'horizontal' | 'vertical',
}

class BlockHeader extends React.Component<Props> {

  render() {
    const { className, title, subtitle, centeredContent, overlappedHeading, titleGap, orientation = 'horizontal', centered, children, link, linkTitle } = this.props;
    return (
      <div className={cn('block-header', className, {
        '-vertical'           : orientation === 'vertical',
        '-centered'           : centered,
        '-centered-content'   : centeredContent,
        '-overlapped-heading' : overlappedHeading,
        '-title-gap'          : titleGap,
      })}>
        {children}
        <h2 className='block-header__title' dangerouslySetInnerHTML={{ __html : title }}/>
        {!!subtitle && (
          <div>
            <div className='title-divider'/>
            <div className='block-header__subtitle'
                 dangerouslySetInnerHTML={{ __html : subtitle }}/>
            {!!link && (
              <SiteLink link={link} linkTitle={linkTitle}/>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default BlockHeader;
