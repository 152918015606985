import { Link } from 'gatsby';
import * as React from 'react';
import FooterBottom from '../FooterBottom/FooterBottom';
import Icon from '../Icon/Icon';

import './ProjectFooter.sass';

class ProjectFooter extends React.Component<{
  link: string,
  title: string,
  className?: string,
  imageSrc: string,
  imageAlt?: string,
  commingSoon?: boolean,
}> {

  render() {
    const { link, title, imageSrc, imageAlt, className, commingSoon } = this.props;
    return (
      <div className={`project-footer ${className}`}>
        <Link to={link}>
          <div className='project-footer__next'>Next Case</div>
          <h2 className='project-footer__title' dangerouslySetInnerHTML={{ __html : title }}/>
          {commingSoon ? <div><div className='underlined'>coming soon</div></div>
            : <Icon name='icon-arrow-down-right' raw/>}
          <img src={imageSrc} alt={imageAlt}/>
        </Link>
        <FooterBottom/>
      </div>
    );
  }
}

export default ProjectFooter;
