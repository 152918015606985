import * as React from 'react';
import Icon from '..//Icon/Icon';

import './ProjectGoals.sass';

class ProjectGoals extends React.Component<{
  challenges: string[],
  solutions?: string[]
}> {

  renderItem = (icon, text, i) => {
    return (
      <div className='project-goals__item' key={i}>
        <Icon name={icon} raw/>
        <div className='project-goals__item-text' dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  };

  render() {
    const { challenges, solutions = [] } = this.props;

    return (
      <div className='project-goals max-width'>
        <div className='project-goals__block -challenge'>
          <div className='project-goals__left'>
            <Icon name='icon-aim' raw/>
            <h3>
              Key Features<br/>
              <span style={{ fontSize: 16, fontWeight: 400, opacity: 0.6, lineHeight: '1.1em !important' }}></span>
            </h3>
          </div>
          <div className='project-goals__right'>
            {challenges.map((item, i) => this.renderItem('icon-check-round', item, i))}
          </div>
        </div>
        {/*<div className='project-goals__block -solution'>*/}
        {/*  <div className='project-goals__left'>*/}
        {/*    <Icon name='icon-solution-puzzle' raw/>*/}
        {/*    <h3>*/}
        {/*      Solution<br/>*/}
        {/*      <span style={{ fontSize: 16, fontWeight: 400, opacity: 0.6, lineHeight: '1.1em !important' }}></span>*/}
        {/*    </h3>*/}
        {/*  </div>*/}
        {/*  <div className='project-goals__right'>*/}
        {/*    {solutions.map((item, i) => this.renderItem('icon-check-round', item, i))}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default ProjectGoals;
