import React, { Component } from "react"
import PageWrapper from "../components/PageWrapper/PageWrapper"
import SEO from "../components/seo"
import Motivetica from "../components/Pages/Projects/Motivetica/Motivetica"

class MotiveticaPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='Motivetica'
          description='Motivetica (Motivation + Helvetica) is an iOS application that shows you inspirational quotes in Helvetica and allows users to set the favorite ones as phone wallpapers.'
        />
        <Motivetica/>
      </PageWrapper>
    )
  }
}

export default MotiveticaPage
