import { Link } from 'gatsby'
import * as React from 'react'
import { ROUTE } from '../../constants/constants'
import HistoryProvider from '../../helpers/HistoryProvider'
import BurgerButton from '../UI/BurgerButton/BurgerButton'
import Icon from '../UI/Icon/Icon'
import cn from 'classnames'

import './Header.sass'

const menuItems = [
  {
    link: '/',
    title: 'Home Page',
  },
  {
    link: '/projects',
    title: 'Projects',
  },
  {
    link: '/about-us',
    title: 'About Us',
  },
  // {
  //   link: '/blog-posts',
  //   title: 'Blog',
  // },
  {
    link: '/#contacts',
    title: 'Contacts',
  },
];

class Header extends React.Component<{}, {
  isOpened: boolean
}> {

  state = {
    isOpened: false,
  }

  componentDidMount() {
    HistoryProvider.addEventListener('onRouteUpdate', this.closeBurger)
  }

  componentWillUnmount(): void {
    HistoryProvider.removeEventListener('onRouteUpdate', this.closeBurger)
  }

  closeBurger = () => {
    if (this.state.isOpened) {
      this.setState({ isOpened: false })
    }
    document.body.style.overflow = 'visible';
  }

  toggleBurger = () => {
    if(this.state.isOpened) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }))
  }

  render() {
    const { isOpened } = this.state
    return (
      <header className={cn('header', {'-opened': isOpened})}>
        <div className='max-width'>
          <Link to={ROUTE.base} aria-label='Link to Home Page' className='header__logo' onClick={this.closeBurger}>
            <Icon name='icon-logo-white' />
          </Link>
          <BurgerButton isOpened={isOpened} onClick={this.toggleBurger}/>
        </div>
        {isOpened && (
          <>
            <div className='header__content max-width'>
              <Icon name='heading-menu' className='heading' raw/>
              <nav className='header__menu'>
                {menuItems.map((item, i) => {
                  return (
                    <Link activeClassName='-active' className='header__menu-item' key={i}
                          to={item.link || '#'} onClick={this.closeBurger}>
                      <span className='header__menu-item-count'>0{i}.</span>
                      {item.title}
                      <span className='header__menu-item-point'/>
                    </Link>
                    )
                })}
              </nav>
            </div>
            <div className='header__bg'/>
          </>
        )}
      </header>
    )
  }
}

export default Header
